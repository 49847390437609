import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Alert } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useAppStore } from "../../App";
import Cookies from "universal-cookie";
import { FetchWithAuth } from "../../services/AuthService";

const cookies = new Cookies();

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [userID, setUserID] = useState(0);
  const [code, setCode] = useState('');
  const history = useHistory();

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  async function sendCode() {
    let input = { username: username, password: password };
    let isMounted = true;
    setLoading(true);
    fetch("/api/kb/login", { method: "POST", body: JSON.stringify(input) })
      .then((response) => response.json())
      .then((res) => {
        if (!res.error) {
          setErrorMessage('');
          setShowAlert(false);
          setLoading(false);
          setStep(2);
          setUserID(res.data);
        } else {
          setErrorMessage(res.data.message);
          setShowAlert(true);
        }
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        if (isMounted) {
          setLoading(false);
        }
      });
  }

  const verify = (e) => {
    e.preventDefault();
    const input = { code: code, id: userID };
    fetch(
      "/api/kb/login-verify",
      { method: "POST", body: JSON.stringify(input) }
    ).then(res => res.json()).then((data) => {
      if (!data.error) {
        cookies.set("refreshToken", data.data.refresh_token);
        useAppStore.setState({ isLoggedin: true })

        FetchWithAuth('/api/user/current/certificate').then(res => res.json()).then((data) => {
          if (!data.error) {
            let roles = data.data.roles ? data.data.roles : []
            let name = data.data.login
            useAppStore.setState({ userRoles: roles, userName: name })
          }
          history.push("/pages/Certificates")
        })

      } else {
        setErrorMessage(data.data.message);
        setShowAlert(true);
      }
    })
  }

  //HTML kode for å vise inputfeltene for innlogging
  return (
    <>
      <div className="container-login bg-white">
        <div className="container-fluid">
          <div className="Login">
            <div className="loginLogo">

              <h1>Log in <img alt="logo" width={60} src="/images/digimaker.png" /></h1>
            </div>
            <div className="login-alert">
              {(showAlert && !loading) && (
                <Alert show={showAlert} variant="danger">
                  {errorMessage}
                </Alert>
              )}
            </div>

            <div className="login-main">
              {step === 1 && <Form onSubmit={(e) => { e.preventDefault(); sendCode(); }}>
                <Form.Group controlId="username">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    size="lg"
                    autoFocus
                    type="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    size="lg"
                    type="password"
                    autoComplete="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Button
                  className="login-button"
                  size="lg"
                  type="submit"
                  disabled={!validateForm() || loading}
                >
                  {(loading && "loading...") || "Login"}
                </Button>
              </Form>}
              {step === 2 && <Form onSubmit={verify}>
                <Form.Group controlId="code">
                  <Form.Label>Please input your code sent to your mobile</Form.Label>
                  <Form.Control
                    autoFocus
                    size="lg"
                    type={(username === 'admin' && "password") || "text"}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </Form.Group>
                <div>
                  <Button
                    className="login-button"
                    size="lg"
                    type="submit"
                    disabled={!validateForm() || loading}
                  >Login</Button>
                  <br />
                  <Button variant="light" onClick={() => sendCode()}>Resend</Button>
                </div>
              </Form>}

              <br />
              {step === 1 && <Link to="/forgot-password">Forgot password?</Link>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;