import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from "react-router-dom";
import './App.css';
import Config from './dm.json';
import 'digimaker-eui/Init';
import util, { GetAccessToken } from 'digimaker-ui/util';
import Dashboard from './pages/dashboard/Dashboard';
import Certificates from './pages/certificates/CertificateController';
import CertificateTemplates from './pages/certificateTemplates/CertificateTemplateController';
import Templates from './pages/templates/TemplateController';
import CertificateManagers from './pages/certificateManagers/CertificateManagerController';
import LoginPage from './pages/login/Login';
import RoleProtectedRoute from './components/RoleProtectedRoute';
import NotFound from './pages/notFound';
import { UserList } from './pages/users/UserList';
import { ForgotPassword } from './pages/login/ForgotPassword';
import { ResetPasswordConfirm } from './pages/login/ResetPasswordConfirm';
import TopNavbarLayout from './components/layout/TopNavbarLayout';
import { useAppStore } from "./App";
import { FetchWithAuth } from './services/AuthService';



const AppRoutes = () => {

  // const { logOut } = useAppStore()
  util.setConfig(Config);
  const history = useHistory();
  const currPath = window.location.pathname
  // util.setCookieKey("dm_eui"); //todo: use .env?

  // when app is loaded:
  // if logged in, get role, redirect to certificates page
  useEffect(() => {
    // console.log("check if logged in")
    GetAccessToken().then(data => {
      useAppStore.setState({ isLoggedin: true })

      FetchWithAuth('/api/user/current/certificate').then(res => res.json()).then((data) => {
        if (!data.error) {
          let roles = data.data.roles ? data.data.roles : []
          let name = data.data.login
          useAppStore.setState({ userRoles: roles, userName: name })
        }
        useAppStore.setState({ initialLoad: true })
        if (currPath === '/' || currPath === '/pages/LoginPage') {
          history.push('/pages/Certificates');
        }
      })
      
    }).catch(err => {
      useAppStore.getState().logOut()
      useAppStore.setState({ initialLoad: true })
    });
  }, [])

  return (
    <>
      <TopNavbarLayout />
      <Switch>

        <Route path="/" exact component={LoginPage} />
        <Route path="/pages/LoginPage" exact component={LoginPage} />
        <Route path="/forgot-password" exact component={ForgotPassword} />
        <Route path="/reset-password/:hash" exact component={ResetPasswordConfirm} />

        <RoleProtectedRoute roles={["admin", "member"]} path="/pages" exact component={Dashboard} />
        <RoleProtectedRoute roles={["admin", "member"]} path="/pages/Dashboard" exact component={Dashboard} />
        <RoleProtectedRoute roles={["admin", "member"]} path="/pages/Certificates" exact component={Certificates} />

        <RoleProtectedRoute roles={["admin"]} redirectPath='/pages/Certificates' path="/pages/CertificateTemplate" exact component={CertificateTemplates} />
        <RoleProtectedRoute roles={["admin"]} redirectPath='/pages/Certificates' path="/pages/Templates" exact component={Templates} />
        <RoleProtectedRoute roles={["admin"]} redirectPath='/pages/Certificates' path="/pages/CertificateManagers" exact component={CertificateManagers} />
        <RoleProtectedRoute roles={["admin"]} redirectPath='/pages/Certificates' path="/pages/Users" exact component={UserList} />

        <Route path="*" exact component={NotFound} />
      </Switch>
    </>
  );
};

export default AppRoutes