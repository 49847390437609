import React, { useEffect, useState } from "react"
import { FetchWithAuth } from "../../services/AuthService";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";

export const AddUser = (props:{onSuccess:()=>void, onCancel:()=>void})=>{    
    const [error, setError] = useState('');

    useEffect(()=>{
                        
  
    }, []);

    const submit = (e)=>{              
        e.preventDefault();  
        const fData = new FormData(e.target);
        const object = {};
        fData.forEach(function(value, key){
            if( value ){
                object[key] = value;
            }
        });

        if( object['password'] !== object['password_repeat'] ){
            setError("Passord doesn't match");
        }else{
            FetchWithAuth(
                '/api/content/create/user/4', {method: 'POST',
                body: JSON.stringify(object)}
              ).then(res=>res.json()).then(data=>{
                if( data.error===false ){
                    props.onSuccess();            
                }
              });
        }

        console.log(object);

    }

    return <div>
        <Form onSubmit={submit}>
            <Row>
                <Col>
                <Form.Group>
                    <Form.Label>First name</Form.Label>
                    <Form.Control name="firstname" required={true} type="text" placeholder="First name" />
                </Form.Group>           
                </Col>
                <Col>
                <Form.Group>
                    <Form.Label>Last name</Form.Label>
                    <Form.Control  name="lastname" required={true} type="text"  placeholder="Last name"  />
                </Form.Group>
            </Col>
            </Row>
           
            <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control  name="email" required={true} type="email"  placeholder="Email"  />
            </Form.Group>
            <Form.Group>
                <Form.Label>Mobile</Form.Label>
                <Form.Control  name="mobile" type="text" placeholder="Mobile"  />
            </Form.Group>
            <Form.Group>
                <Form.Label>Username</Form.Label>
                <Form.Control  name="login" required={true} type="text"  placeholder="login account(no space)"  />
            </Form.Group>
            <Row>
                <Col>
                    <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control  name="password" required={true} type="password"  placeholder="Password"  />
                </Form.Group>  
                </Col>
                <Col>
                    <Form.Group>
                    <Form.Label>Password repeat</Form.Label>
                    <Form.Control  name="password_repeat" required={true} type="password" placeholder="Password repeat" />
                </Form.Group>  
                </Col>
            </Row>
                    
             
            {error&&<Alert variant="warning">{error}</Alert>}        
            <div>
                <br />
                <Button type="submit">Submit</Button>
                <Button variant="light" onClick={()=>props.onCancel()}>Cancel</Button>
            </div>
        </Form>
    </div>
}