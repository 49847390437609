import Cookies from 'universal-cookie';
import { useAppStore } from '../App';

const cookies = new Cookies();

//todo: move this token into another file(auth).
let accessToken: any = null; //access token, which is a promise<string>

export function FetchWithAuth(url: string, reqObj?: any) {
  return GetAccessToken()
    .catch(err => {
      console.error(err); // todo: rediction or prompt message here or outside? returning a renderable error component might be good?
      throw err;
    }).then(token => {

      let newAccessToken = JSON.parse(token);
      // add Authorization into header
      let authValue = 'Bearer ' + newAccessToken.data;
      if (reqObj) {
        if (reqObj.headers) {
          reqObj.headers.delete('Authorization'); //If there is Authorization already, replace with this token
          reqObj.headers.append('Authorization', authValue);
        } else {
          reqObj.headers = new Headers({ 'Authorization': authValue });
        }
      } else {
        reqObj = { headers: new Headers({ 'Authorization': authValue }) };
      }

      //fetch
      return fetch(url, reqObj).then(res => {
        if (res.status === 440) { //if it expired, renew token and refetch with new token
          accessToken = null;
          return FetchWithAuth(url, reqObj);
        }
        return res;
      })
    });
}

//get new/renewed/cached access token, return promise<token>
//todo: use sigleton way to make sure it will only request once when accessToken is empty.
export function GetAccessToken() {
  if (!accessToken) {
    let refreshToken = util.getRefreshToken();
    if (!refreshToken) {
      return new Promise((resolve, reject) => {
        reject({ code: '0001', message: 'No token found.' });
      });
    }
    accessToken = fetch('/api/auth/token/access/renew?token=' + refreshToken)
      .then(res => {
        if (!res.ok) {
          useAppStore.getState().logOut();
          throw { code: '0001', message: "Can not proceed because of invalid authorization. Need to relogin?" };
        }
        accessToken = res.text();
        return accessToken
      });
  }

  return accessToken
}

//Set access token. Useful when eg login.
export function SetAccessToken(token: string) {
  accessToken = new Promise(func => func(token))
}

//todo: make sure it only fetch once for one content type.


let config: any = null;
//util for general operations
const util = {
  //put replace variable with real value.eg. "this is {id}" with {'id': 5} will be "this is 5"
  washVariables: (str: string, values: any) => {
    let variables = str.match(/{(\w+|_)}/g);
    let result = str;
    if (variables) {
      variables.forEach(ele => {
        let variable = ele.replace('{', '').replace('}', ''); //todo: use regular expression better instead of replace
        if (values[variable]) {
          result = result.replace('{' + variable + '}', values[variable])
        }
      });
    }
    return result;
  },

  // get value from expression. eg. "relations.apartment.data"
  getValue: (expression: string, value: any) => {
    let arr = expression.split('.');
    let result = value;
    for (let key of arr) {
      if (result[key]) {
        result = result[key];
      } else {
        result = '';
        break;
      }
    }
    return result;
  },

  cookieKey: 'refreshToken',

  getRefreshToken: () => {
    return cookies.get(util.cookieKey)
  },

  //set key in cookie, useful when there is multi site in one domain.
  setCookieKey: (key: string) => {
    util.cookieKey = key;
  },

  getCookieKey: () => {
    return util.cookieKey;
  },

  //  alertFunc:null,

  //  alert:(message:any, title:string, type:string)=>{
  //   if( util.alertFunc ){
  //     util.alertFunc( message, type, title );
  //   }else{
  //     window.alert( message );
  //   }
  //  },

  setConfig: (conf: any) => {
    config = conf;
  },

  getConfig: () => {
    return config;
  },

  //get allowed type under the parent content. condition example: "article:3 or article:images"
  //only support id(3 is an ancestor id in the example) and subtype(images is the subtype of parent)
  getAllowedType: (content: any, condition: string) => {
    if (!condition) {
      return false;
    }
    let arr = condition.split(':');
    let type = arr[0];
    if (arr.length === 1) {
      return type;
    } else {
      let value: any = arr[1];
      if (isNaN(value) && content.subtype && content.subtype === value) {
        return type;
      } else if (!isNaN(value) && content.hierarchy.split('/').includes(value)) {
        return type;
      }
    }
    return false;
  },


  mergeSettings: (first, second) => {
    let result = {};

    //todo: replace this with getDefinition(key).has_location after getDefinition is async
    if (second["no_override"]) {
      return second;
    }

    //get all setting keys
    let settingKeys = Object.keys(second);
    for (let setting of Object.keys(first)) {
      if (!settingKeys.includes(setting)) {
        settingKeys.push(setting);
      }
    }

    for (let setting of settingKeys) {
      let value = second[setting];
      let firstValue = first[setting];
      if (value === undefined) {
        if (firstValue !== undefined) {
          result[setting] = firstValue;
        }
        continue;
      }

      //when setting value is array
      if (Array.isArray(value) && firstValue !== undefined) {
        //do not merge
        let removed = -1;
        for (let i = 0; i < value.length; i++) {
          if (value[i] === "-") {
            removed = i;
            break;
          }
        }
        if (removed !== -1) {
          let valueCopy = [...value];
          valueCopy.splice(removed);
          result[setting] = valueCopy;
          continue;
        }

        //merge

      }

      //when setting value is object
      if (typeof value === 'object' && firstValue !== undefined) {
        result[setting] = { ...firstValue, ...value };
        continue;
      }

      //override in other cases
      result[setting] = value;
    }
    return result;
  },

  _settingCache: {},

  //For array it will replace
  getSettings: (settings: any, key: string, type: string) => {
    let cacheKey = key + '-' + type;
    if (util._settingCache[cacheKey] === undefined) {
      let result = { ...settings["*"] };
      let arr = key.split(":");

      if (settings[arr[0]]) {
        console.log(settings[arr[0]]);
        result = util.mergeSettings(result, settings[arr[0]]);
      }
      if (arr.length === 2 && settings[key]) {
        result = util.mergeSettings(result, settings[key]);
      }
      util._settingCache[cacheKey] = result;
    }
    return util._settingCache[cacheKey];
  }

}

export default util;
