import React from 'react';

const NotFound = () => {
  return (
    <>
      <div className='center-screen'>404: Page not found</div>
    </>
  )
}

export default NotFound;