import React from 'react'
import DataTable from 'react-data-table-component'
import { FetchWithAuth, GetAccessToken } from '../../services/AuthService'
import Button from 'react-bootstrap/Button'
import { useTemplateInput } from './TemplateInput'
import ConfirmDelete from '../../components/ConfirmDelete'



interface IProps {
}

interface StateData {
  [stateData: string]: any
}

interface ColumnData {
  [columnData: string]: any
}

interface StateId {
  id: Number,
  web: String
}

export default class ListTemplate extends React.Component<IProps, StateData, ColumnData> {

  constructor(props: any) {
    super(props)
    this.state = {
      stateData: [],
      columnData: [],
      showConfirmDeleteModal: false,
      confirmDeleteMessage: '',
      deleteId: 0,
      deleteLoading: false,
    }
  }

  componentDidMount() {
    this.loadData()
    useTemplateInput.setState({ reloadList: this.loadData })
  }

  togleConfirmDelete = (id, name) => {
    if (!name) {
      name = 'template id ' + id
    }
    this.setState({
      deleteId: id,
      confirmDeleteMessage: 'Delete ' + name + '?',
      showConfirmDeleteModal: !this.state.showConfirmDeleteModal,
    })
  }

  loadData = () => {
    const columnNames: any = []

    FetchWithAuth(
      '/api/content/list/template?&columns=true', GetAccessToken()
    ).then(res => res.json()).then((resp) => {
      for (let columns in resp.data.columns) {
        if (columns === 'card' || columns === 'letter') {
          continue
        }
        columnNames.push({ 'name': columns, 'selector': columns, 'sortable': true })
      }

      columnNames.push({
        'name': 'Actions', 'selector': (row, index) => {
          return <div>
            <Button variant="link" onClick={() => this.updateRow(row)}>
              Edit
            </Button>
            <Button variant="link" onClick={() => this.togleConfirmDelete(row.id, row.name)}>
              Delete
            </Button>
          </div>
        },
      })

      this.setState({ stateData: resp.data.list })
      this.setState({ columnData: columnNames })

    }).catch(err => {
      console.log(err.message)
    })
  }

  updateRow = (item) => {
    useTemplateInput.getState().open({ item })
  }

  deleteRow = () => {
    this.setState({ deleteLoading: true })
    if (this.state.deleteId <= 0) {
      return;
    }

    FetchWithAuth(
      '/api/content/delete?type=template&cid=' + this.state.deleteId, GetAccessToken()
    ).then(res => res.json()).then(data => {
      if (data.error === true) {
        window.alert('Error')
      } else {
        this.loadData()
        this.setState({
          showConfirmDeleteModal: false,
          deleteLoading: false,
        })
      }
    })
  }

  render() {
    return (
      <>
        <DataTable
          columns={this.state.columnData}
          data={this.state.stateData}
          pagination
          highlightOnHover
          noHeader
          onRowClicked={(row, event) => {
            this.updateRow(row)
          }}
        />

        <ConfirmDelete
          show={this.state.showConfirmDeleteModal}
          onHide={this.togleConfirmDelete}
          confirmDelete={this.deleteRow}
          message={this.state.confirmDeleteMessage}
          loading={this.state.deleteLoading}
        />
      </>
    )
  }
}


