import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { FetchWithAuth } from '../../services/AuthService'
import { Col, Row } from 'react-bootstrap'
import { create } from 'zustand'
import CloseSubmitFooter from '../../modal/closeSubmitFooter'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type Notation1 = {
  id: number,
  template_id: number,
  notation: string,
  notations2: {
    [key: number]: Notation2
  };
}

interface Notation2 {
  id: number,
  notation1_id: number,
  notation: string,
}

const _formItem = {
  name: '',
  numberprefix: '',
  validfor: 12,
  startnumber: 1,
  currentnumber: 1,
  notations1: '',
  notations2: '',
  print_template: 0,
  id: 0,
}

type FormItem = typeof _formItem
export const useCertificateTemplateInput = create<{
  showModal: boolean
  form: Partial<FormItem>
  oldForm: Partial<FormItem>
  editForm: ([key, value]: [keyof FormItem, any]) => any
  open({ item }: { item?: Partial<FormItem> }): void
  delete({ item }: { item?: Partial<FormItem> }): void
  reloadList: any
}>((setState, getState) => ({
  showModal: false,
  form: {
    startnumber: 1,
    validfor: 12,
    print_template: 0,
  },
  oldForm: {},
  editForm([key, value]) {
    setState(state => ({
      ...state,
      form: {
        ...state.form,
        [key]: value,
      },
    }))
  },
  open({ item }: { item?: Partial<FormItem> }) {
    setState({
      showModal: true,
      form: {
        startnumber: 1,
        validfor: 12,
        print_template: 0,
        ...(item ?? {}),
      },
      oldForm: {
        ...(item ?? {}),
      },
    })
  },
  async delete({ item }: { item?: Partial<FormItem> }) {
    if (item?.id) {
      const res = await FetchWithAuth(`/api/content/delete?type=certificate_template&cid=${item?.id}`).then((res) => res.json())
      if (res) {
        getState()?.reloadList?.()
      }
    }
  },
  reloadList() {
  },
}))

export default function CertificateTemplateInput() {
  const { form, oldForm, editForm, showModal } = useCertificateTemplateInput()
  const {
    startnumber,
    currentnumber,
    notations1,
    notations2,
    print_template,
    validfor,
    numberprefix,
    name
  } = form
  const [isLoading, setIsLoading] = useState(false)
  const [printTemplates, setPrintTemplates] = useState<Array<any>>([])


  useEffect(() => {
    FetchWithAuth('/api/content/list/template')
      .then((res) => res.json())
      .then((data) => {
        setPrintTemplates(data.data.list)
      });
  }, [])

  function handleSubmit() {
    setIsLoading(true)
    let changeCurrentNumber = oldForm.startnumber !== form.startnumber

    const body = {
      name: name,
      numberprefix: numberprefix,
      validfor: validfor,
      startnumber: startnumber?.toString(),
      currentnumber: changeCurrentNumber ? startnumber?.toString() : currentnumber,
      notations1: notations1,
      notations2: notations2,
      print_template: print_template,
    }

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body),
    }

    FetchWithAuth(
      form?.id ?
        `/api/content/update/certificate_template/${form.id}` :
        '/api/content/create/certificate_template',
      requestOptions
    ).then((res) => {
      setIsLoading(false)
      if (res.status === 200) {
        useCertificateTemplateInput.setState({ showModal: false })
        useCertificateTemplateInput.getState()?.reloadList?.()
      } else {
        res.json().then((data) => {
          if (data.error === true) {
            alert(data.data.message)
          }
        })
      }
    }).catch((err) => {
      setIsLoading(false)
      console.log(err.message)
    })
  }

  const changeNotation1 = (value) => {
    /**
     * automatically, places the notation1 in notation2
     * given notation1  = '1,2,3'
     * notation2 should = '1: \n2: \n3: '
     * 
     * if notation 2 was = 'a \nb'
     * new notation2     = '1: a\n2: b\n3: '
     * 
     * NOT working atm
     */
    // console.log("----------------")
    // var notats1 = [];
    // notats1 = value.split(",").map(function (item) {
    //   return item.trim();
    // });

    // var notation2_string = '';
    // if (notations2) {
    //   const notation2_groups = notations2.split("\n").map(function (item) {
    //     if (item.split(':').length > 1) {
    //       return item.split(':')[1];
    //     }
    //     if (item) {
    //       return item;
    //     }
    //   });

    //   notation2_groups.forEach(notat2 => {
    //     if (notats1.length > 0) {
    //       console.log("not[0]", notats1)
    //       notation2_string += notats1[0] + ':';
    //       notats1.splice(0, 1);
    //     } else {
    //       notation2_string += '!!!:';
    //     }
    //     notation2_string += notat2 + '\n';
    //   });
    // }

    // notats1.forEach((notat1, index) => {
    //   notation2_string += notat1 + ': ';
    //   if (notats1.length > index + 2) {
    //     notation2_string += '\n';
    //   }
    // });

    // editForm(['notations2', notation2_string])





    // var notations1 = [];
    // var notations = [];
    // notations = value.split(",").map(function (item) {
    //   return item.trim();
    // });

    // notations.forEach(notation => {
    // })


    editForm(['notations1', value])
  }

  return (
    <>
      <Modal
        size="xl"
        show={showModal}
        onHide={() => useCertificateTemplateInput.setState({ showModal: false })}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{form?.id ? 'Update Certificate Template' : 'Create Certificate Template'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container" id="myModal">
            <form action="#">
              <div className="form-group">
                <label htmlFor="name">
                  Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => editForm(['name', e.target.value])}
                  placeholder="Name"
                  className="form-control"
                  id="name"
                />
              </div>

              <Row>
                <Col xs={4}>
                  <div className="form-group">
                    <label htmlFor="standard">Print template: <span className="text-danger">*</span></label>
                    <select className="form-control"
                      value={print_template}
                      onChange={e => editForm(['print_template', parseInt(e.target.value)])}>
                      <option value="0">Select</option>
                      {printTemplates.map(
                        (item) => <option key={item.id} value={item.id}>{item.name}</option>,
                      )}
                    </select>
                  </div>
                </Col>
              </Row>

              <div className="form-group">
                <label htmlFor="notations1">
                  Notations 1: <span style={{ fontSize: "14px" }}>(split with ";")</span>
                </label>
                <input
                  type="text"
                  value={notations1}
                  onChange={(e) => changeNotation1(e.target.value)}
                  className="form-control">
                </input>
              </div>

              <div className="form-group">
                <label htmlFor="notations2">
                  Notations 2: <span style={{ fontSize: "14px" }}>(match notations 1 splits with new lines, split with ";")</span>
                </label>
                <textarea
                  wrap="off"
                  rows={notations2?.split("\n").length}
                  value={notations2}
                  onChange={(e) => editForm(['notations2', e.target.value])}
                  className="form-control"></textarea>
              </div>

              <Row>
                <Col xs={4}>
                  <div className="form-group">
                    <label htmlFor="startnumber">Start number&nbsp;
                      {form?.id && (
                        <InfoOutlinedIcon style={{ width: "22px" }} titleAccess='Changing start number will update current number' />
                      )}
                    </label>
                    <input
                      type="number"
                      value={startnumber}
                      className="form-control"
                      onChange={(e) => editForm(['startnumber', parseInt(e.target.value)])}
                      id="startnumber"
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={4}>
                  <div className="form-group">
                    <label htmlFor="numberprefix">
                      Examination Center Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      value={numberprefix}
                      onChange={(e) => editForm(['numberprefix', e.target.value])}
                      className="form-control"
                      id="numberprefix"
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={4}>
                  <div className="form-group">
                    <label htmlFor="validfor">
                      Valid for (months) <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      value={validfor}
                      onChange={(e) => editForm(['validfor', e.target.value])}
                      className="form-control"
                      id="validfor"
                    />
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Modal.Body>
        <CloseSubmitFooter
          onClose={() => useCertificateTemplateInput.setState({ showModal: false })}
          onSubmit={handleSubmit}
          submitText={form?.id ? 'Save' : 'Create'}
          loadingText={form?.id ? 'Saving...' : 'Creating...'}
          loading={isLoading}
          showLoadingSpinner={true}
        />
      </Modal >
    </>
  )
}
