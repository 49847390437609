import React from 'react'
import Button from 'react-bootstrap/Button'
import ListTemplate from './ListTemplates'
import TemplateInput, { useTemplateInput } from './TemplateInput'

interface IProps {
  props?: string;

}

export default class TemplateController extends React.Component<{}, { show: boolean }, IProps> {

  constructor(props: any) {
    super(props)
    this.state = { show: false }
    this.handleShow = this.handleShow.bind(this)
  }

  handleShow() {
    let value = this.state.show
    this.setState({ show: !value })
  }

  render() {
    return (
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <br />
                <h2>Templates</h2>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card-box">
                  <div className="create-new-button">
                    <Button onClick={() => useTemplateInput.getState().open({})}
                      type="button"
                      className="btn btn-info"
                      data-toggle="modal"
                      data-target="#myModal"
                      color="primary">
                      New template
                    </Button>
                  </div>
                  <div className="IssuedCertificatesTable">
                    <TemplateInput />
                    <ListTemplate />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

