import React from 'react'
import Button from 'react-bootstrap/Button'
import SettingsManagerInput from './CertificateManagerInput'
import ListManagerCerts from './ListCertificateManagers'
import { useSettingsManagerInput } from './CertificateManagerInput'

interface IProps {
  props?: string;
}

export default class CertificateManagerController extends React.Component<{}, { show: boolean }, IProps> {

  constructor(props: any) {
    super(props)
    this.state = { show: false }
    this.handleShow = this.handleShow.bind(this)
  }

  handleShow() {
    let value = this.state.show
    this.setState({ show: !value })
  }

  render() {
    return (
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <br />
                <h2>Certificate managers</h2>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card-box">
                  <div className="create-new-button">
                    <Button onClick={() => useSettingsManagerInput.getState().open({})}
                      type="button"
                      className="btn btn-info"
                      data-toggle="modal"
                      data-target="#myModal"
                      color="primary">
                      New manager
                    </Button>
                  </div>
                  <div className="IssuedCertificatesTable">
                    <SettingsManagerInput />
                    <ListManagerCerts />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

