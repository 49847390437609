import * as React from 'react';
// import { Donutchart } from './Donutchart';
// import { Barchart } from './Barchart';


//dashboard kode
const Dashboard = () => {


  return (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <br />
              <div>
                <h2>Welcome to Digimaker certificate management system</h2>
              </div> <br />
            </div>
          </div>
          {/* boks der man kan sette inn tekst */}
          <div className="row">
            <div className="col-12">
              <div>
                <div className="row">
                  {/* <div className="col-sm-12 box-container">
                    <div className="card">
                    <div className="card-body">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec blandit fermentum ante. Suspendisse ac ligula erat. 
                        Morbi id arcu tempus, commodo lectus sed, facilisis nisl. Suspendisse quis sem sollicitudin, iaculis ante in, iaculis nunc.
                        aecenas pretium velit arcu, id luctus nisi ullamcorper ut. Nullam vel facilisis arcu. Aenean et arcu sodales, faucibus ipsum 
                        vitae, mattis est. Nulla suscipit dapibus commodo. Nunc eu magna ac sapien commodo tincidunt. Nullam faucibus volutpat lectus,
                        a consectetur mauris laoreet non. Cras vitae erat finibus, fermentum nisi ac, pellentesque justo.</p>
                    </div>
                    </div>
                  </div> */}
                </div>
                <br /><br />

                {/* visning av fremdriftsbarene */}
                <div className="card-box widget-inline">
                  <div className="row">
                    <div className="col-xl-6 col-sm-6 widget-inline-box">

                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex mb-4">
                            <div className="text-right ml-auto">
                              <p className="text-certificates-dash-box mb-2">Total Certificates</p>
                              <h2 className="mb-0 number-font">5</h2>
                            </div>
                          </div>
                          <div className="progress progress-md h-2">
                            <div className="progress-bar progress-bar-striped progress-bar-animated bg-primary w-50">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-sm-6 widget-inline-box">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex mb-4">
                            <div className="text-right ml-auto">
                              <p className="text-certificates-dash-box mb-2">Total Managers </p>
                              <h2 className="mb-0 number-font">10</h2>
                            </div>
                          </div>
                          <div className="progress progress-md h-2">
                            <div className="progress-bar progress-bar-striped progress-bar-animated bg-second w-10">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* visning av sektordiagram og søylediagram */}


          {/* <div className="row">
          
          <div className="col-xl-6 col-sm-6 box-container">
            
            <div className="card right">
             <div className="card-body">
              <h6 className="mt-0 font-14 mb-3"> test box </h6>
                {Donutchart()}
             </div>
            </div>
            
          </div>
          
          <div className="col-xl-6 col-sm-6 box-container">
            <div className="card">
             <div className="card-body bar">
               <h6 className="mt-0 font-14 mb-3"> test box </h6>
               {Barchart()}
              </div>
            </div>
          </div>

        </div> */}

        </div>
      </div>
    </div>
  )
}


export default Dashboard