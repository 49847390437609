import React, { useEffect, useState } from "react"
import { FetchWithAuth, GetAccessToken } from "../../services/AuthService";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { UpdateUser } from "./UpdateUser";
import { Button, Modal } from "react-bootstrap";
import { AssignRole } from "./AssignRole";
import { AddUser } from "./AddUser";
import DeleteIcon from '@mui/icons-material/Delete';

export const UserList = () => {
  const [list, setList] = useState([]);
  const [roles, setRoles] = useState({} as any);
  const [shown, setShown] = useState(false);

  const [updateId, setUpdateId] = useState(0);
  const [assignParam, setAssignParam] = useState(0);
  const [assignShown, setAssignShown] = useState(false);

  const [createNew, setCreateNew] = useState(false);

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteID, setDeleteID] = useState(0);


  useEffect(() => {
    init();
  }, []);

  const init = () => {
    FetchWithAuth(
      '/api/content/list/user?sortby=c.id desc', GetAccessToken()
    ).then(res => res.json()).then((data) => {
      setList(data.data.list.filter(i => i.id != 2));

      let newRoles = { ...roles };
      for (const item of data.data.list) {
        FetchWithAuth('/api/access/roles/' + item.id, GetAccessToken()).then(res => res.json()).then(data1 => {
          newRoles[item.id] = data1.data;
          if (Object.keys(newRoles).length === data.data.list.length) {
            setRoles(newRoles);
          }
        })
      }
    })
  }

  const unAssign = (userID, roleID) => {
    FetchWithAuth(
      '/api/access/unassign/' + userID + '/' + roleID
    ).then(res => res.json()).then(data => {
      init();
    });
  }

  const deleteUser = (userID) => {
    setDeleteDialog(true)
    setDeleteID(userID);
  }

  const submitDeleteUser = () => {
    FetchWithAuth(
      '/api/content/delete?type=user&cid=' + deleteID).then(res => res.json()).then(data => {
        setDeleteDialog(false);
        setDeleteID(0);
        init();
      });
  }


  return <div className="content-page">
    <div className="content">
      <h2>Users</h2>
      <div className="right"><Button onClick={() => setCreateNew(true)}>Create new</Button></div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component="th">
              ID
            </TableCell>
            <TableCell component="th">
              First name
            </TableCell>
            <TableCell component="th">
              Last name
            </TableCell>
            <TableCell component="th">
              Username
            </TableCell>
            <TableCell component="th">
              Email
            </TableCell>
            <TableCell component="th">
              Mobile
            </TableCell>
            <TableCell component="th">
              Roles
            </TableCell>
            <TableCell component="th">
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map(row => <TableRow
            key={row['id']}>
            <TableCell component="td" scope="row">
              {row['id']}
            </TableCell>
            <TableCell component="td" scope="row">
              {row['firstname']}
            </TableCell>
            <TableCell component="td" scope="row">
              {row['lastname']}
            </TableCell>
            <TableCell component="td" scope="row">
              {row['login']}
            </TableCell>
            <TableCell component="td" scope="row">
              {row['email']}
            </TableCell>
            <TableCell component="td" scope="row">
              {row['mobile']}
            </TableCell>
            <TableCell key={roles[row['id']]}>
              {roles[row['id']] && roles[row['id']].map((role) =>
                <div key={role.id}>{role.name} <Button variant="link" onClick={() => unAssign(row['id'], role.id)}><DeleteIcon /></Button></div>
              )}
            </TableCell>
            <TableCell component="td" scope="row">
              <Button variant="link" onClick={() => {
                setShown(true);
                setUpdateId(row['id']);
              }}>Edit</Button>
              <Button variant="link" onClick={() => {
                setAssignShown(true);
                setAssignParam(row['id']);
              }}>Assign role</Button>
              <Button variant="link" onClick={() => deleteUser(row['id'])}>Delete</Button>
            </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </div>


    <Modal show={shown} onHide={() => setShown(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Edit user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UpdateUser onSuccess={() => { init(); setShown(false) }} onCancel={() => setShown(false)} id={updateId} />
      </Modal.Body>

    </Modal>

    <Modal show={assignShown} onHide={() => setAssignShown(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Assign roles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AssignRole onSuccess={() => { init(); setAssignShown(false) }} onCancel={() => setAssignShown(false)} id={assignParam} />
      </Modal.Body>

    </Modal>

    <Modal show={createNew} onHide={() => setCreateNew(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Create user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddUser onSuccess={() => { init(); setCreateNew(false) }} onCancel={() => setCreateNew(false)} />
      </Modal.Body>

    </Modal>


    <Modal show={deleteDialog} onHide={() => setDeleteDialog(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Delete?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Cofirm to delete user?
      </Modal.Body>
      <Modal.Footer>

        <Button variant="link" onClick={() => submitDeleteUser()}>
          Confirm
        </Button>
        <Button onClick={() => setDeleteDialog(false)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>

  </div>
}