import React from 'react'
import Button from 'react-bootstrap/Button'
import ListCertificateTemplate from './ListCertificateTemplates'
import CertificateTemplateInput, { useCertificateTemplateInput } from './CertificateTemplateInput'


interface IProps {
  props?: string;
}

const CertificateTemplateController = () => {
  const { open } = useCertificateTemplateInput()

  return (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <br />
              <h2>Certificate Templates</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card-box">
                <div className="create-new-button">
                  <Button onClick={() => open({})}
                    type="button"
                    className="btn btn-info"
                    data-toggle="modal"
                    data-target="#myModal"
                    color="primary">
                    New certificate template
                  </Button>
                </div>
                <div className="IssuedCertificatesTable">
                  <CertificateTemplateInput />
                  <ListCertificateTemplate />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CertificateTemplateController