import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { FetchWithAuth } from '../../services/AuthService'
import { create } from 'zustand'
import CloseSubmitFooter from '../../modal/closeSubmitFooter'


const _formItem = {
  card: '',
  id: 28,
  letter: '',
  name: '',
}

type FormItem = typeof _formItem
export const useTemplateInput = create<{
  showModal: boolean
  form: Partial<FormItem>
  editForm: ([key, value]: [keyof FormItem, any]) => any
  open({ item }: { item?: Partial<FormItem> }): void
  reloadList: any
}>((setState, getState) => ({
  showModal: false,
  form: {},
  editForm([key, value]) {
    setState(state => ({
      ...state,
      form: {
        ...state.form,
        [key]: value,
      },
    }))
  },
  open({ item }: { item?: Partial<FormItem> }) {
    setState({
      showModal: true,
      form: {
        ...(item ?? {}),
      },
    })
  },
  reloadList() {
  },
}))

export default function TemplateInput() {
  const { form, editForm, showModal } = useTemplateInput()
  const { card, letter, name } = form
  const [isLoading, setIsLoading] = useState(false)

  function handleSubmit() {
    setIsLoading(true)
    const body = {
      name, card, letter,
    }

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body),
    }

    FetchWithAuth(
      form?.id ?
        `/api/content/update/template/${form.id}` :
        '/api/content/create/template',
      requestOptions
    ).then((res) => {
      setIsLoading(false)
      if (res.status === 200) {
        useTemplateInput.setState({ showModal: false })
        useTemplateInput.getState()?.reloadList?.()
      }
    }).catch((err) => {
      console.log(err.message)
      setIsLoading(false)
    })
  }


  return (
    <>
      <Modal
        size="xl"
        show={showModal}
        onHide={() => useTemplateInput.setState({ showModal: false })}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{form?.id ? 'Update Template' : 'Create Template'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container" id="myModal">
            <form action="#">
              <div className="form-group">
                <label htmlFor="name">Template name <span className="text-danger">*</span></label>
                <input type="text"
                  value={name}
                  onChange={(e) => editForm(['name', e.target.value])}
                  placeholder="name"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="letter">Letter template <span className="text-danger">*</span></label>
                <textarea
                  aria-multiline
                  rows={15}
                  value={letter}
                  onChange={(e) => editForm(['letter', e.target.value])}
                  placeholder="Letter"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="card">Card template <span className="text-danger">*</span></label>
                <textarea
                  aria-multiline
                  rows={15}
                  value={card}
                  onChange={(e) => editForm(['card', e.target.value])}
                  placeholder="Card"
                  className="form-control"
                />
              </div>
            </form>
          </div>
        </Modal.Body>
        <CloseSubmitFooter
          onClose={() => useTemplateInput.setState({ showModal: false })}
          onSubmit={handleSubmit}
          submitText={form?.id ? 'Save' : 'Create'}
          loadingText={form?.id ? 'Saving...' : 'Creating...'}
          loading={isLoading}
          showLoadingSpinner={true}
        />
      </Modal>
    </>
  )
}
