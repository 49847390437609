import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import './App.css';
import 'digimaker-eui/Init';
import { create } from "zustand";
import AppRoutes from './AppRoutes';
import Cookies from "universal-cookie";
import util from 'digimaker-ui/util';

const cookies = new Cookies();

function removeCookies() {
  cookies.remove(util.getCookieKey(), { 'path': '/' });
  cookies.remove(util.getCookieKey(), { 'path': '/pages' });
  cookies.remove('selectedColumns');
}

export const useAppStore = create<{
  isLoggedin: boolean;
  initialLoad: boolean;
  userName: string;
  userRoles: string[];
  logOut(): any;
}>((setState, getstate) => ({
  isLoggedin: false,
  initialLoad: false,
  userName: '',
  userRoles: [],
  logOut: () => {
    let refreshToken = cookies.get(util.getCookieKey())
    var shouldLogout = false;
    if (refreshToken) {
      fetch(
        process.env.REACT_APP_REMOTE_URL + '/auth/token/revoke?token=' + refreshToken
      ).then(res => {
        res.json().then(res => {
          if (!res.error) {
            shouldLogout = true;
          } else {
            // handle error, change to true if handled, default to false for now
            if (res.data?.code === '10001') {
              // "Expired revoked", "token contains an invalid number of segments"
              shouldLogout = true;
            }
          }

          if (shouldLogout) {
            removeCookies();
            window.location.href = '/'; // bug with menu opener when logging out and in if window is not refreshed
            // setState({ isLoggedin: false, userRoles: [], userName: '' });
          }
        })
      }).catch(err => {
        removeCookies();
        window.location.href = '/';
        // setState({ isLoggedin: false, userRoles: [], userName: '' });
      });
    }
  },
}));

const App = () => {
  return (
    <Router>
      <div className="App">
        <AppRoutes />
      </div>
    </Router>
  );
};

export default App;