import React from "react";
import DataTable from "react-data-table-component";
// import DataTableExtensions from "react-data-table-component-extensions";
import { FetchWithAuth } from "digimaker-ui/util";
import { GetAccessToken } from '../../services/AuthService'
import Cookies from "universal-cookie";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { enumStatus, useCertificateModalStore } from "./CertificateController";
import CloseSubmitFooter from "../../modal/closeSubmitFooter";
import DeleteIcon from '@mui/icons-material/Delete';
// import ReplayIcon from '@mui/icons-material/Replay';
// import EditIcon from '@mui/icons-material/Edit';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import ConfirmDelete from '../../components/ConfirmDelete';
import { useAppStore } from "../../App";
// import { create } from "zustand";

const cookies = new Cookies();

interface IProps {
  updateCount: number;
}

interface StateData {
  [stateData: string]: any;
  selected: Array<string>;
  showSelectColumns: boolean;
}

interface ColumnData {
  [columnData: string]: any;
}

interface StateId {
  contentType: String;
  cid: Number;
  author: String;
  authorName: String;
  relations: String;
  description: String;
  dismissed: Boolean;
  externalid: String;
  holderdob: Date;
  holdername: String;
  firstname: String;
  lastname: String;
  holdersignature: String;
  holder_image: String;
  id: Number;
  issueddate: Date;
  issuerlogo: String;
  logo: String;
  managedby: Number;
  name: String;
  notations1: String;
  notations2: String;
  notationsid: Number;
  number: Number;
  prefix: String;
  privatereasondismissed: String;
  publicreasondismissed: String;
  reason_for_loss: String,
  qrcode: String;
  templateid: Number;
  validfrom: Date;
  validto: Date;
}

// var tempData: StateId[] = [];
export default class ListCertificates extends React.Component<
  IProps,
  StateData,
  ColumnData
> {
  private allColumns: Array<any> = [];

  constructor(props: any) {
    super(props);
    this.state = {
      stateData: [],
      columnData: [],
      selected: [],
      show: false,
      showSelectColumns: false,
      updateCount: 0,
      showDeactivateModal: false,
      editCertificate: undefined,
      reason_for_loss: "",
      showConfirmDeleteModal: false,
      confirmDeleteMessage: '',
      deleteId: 0,
      activateLoading: false,
      showChangeValidToModal: false,
      random_id: Math.floor(Math.random() * 99999),
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.submitColumns = this.submitColumns.bind(this);
    this.selectCheckbox = this.selectCheckbox.bind(this);
  }

  componentDidMount() {
    this.loadDataWithColumns()
  }

  componentDidUpdate(prevProps, prevState) {
    // Reload the table data without the columns whenever the updateCount changes
    if (prevProps.updateCount !== this.props.updateCount) {
      this.loadData()
    }
  }

  showModal = (innData: any) => {
    if (innData.status === enumStatus.activated || innData.status === enumStatus.deactivated) {
      return;
    }
    useCertificateModalStore.setState({
      show: true,
      templateData: {},
      updateItem: innData,
      tabValue: 0,
    })
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  loadData = () => {
    FetchWithAuth(
      process.env.REACT_APP_REMOTE_URL +
      "/content/list/certificate?limit=9999&sortby=number desc"
    ).then((resp) => {
      let list = this.formatCertificateDates(resp.data.list)
      this.setState({
        stateData: list,
      });
    }).catch((err) => {
      if (err.code === '0001') {
        useAppStore.getState().logOut();
      } else {
        console.log(err.message);
      }
    });
  }

  loadDataWithColumns = () => {
    //henter api for certificate og filtrerer kolonnene slik at data kommer inn i dem via en for løkke
    FetchWithAuth(
      process.env.REACT_APP_REMOTE_URL +
      "/content/list/certificate?limit=9999&sortby=number desc&columns=true"
    ).then((resp) => {

      const columnNames = new Array({
        name: "ID",
        selector: "id",
        sortable: true,
        wrap: true,
        minWidth: "100px",
      });

      let selectedColumns = cookies.get('selectedColumns')

      if (!selectedColumns) {
        selectedColumns = [
          // "id",
          "number",
          "firstname",
          "lastname",
          "issueddate",
          "validfrom",
          "validto",
        ];
      }

      let largerColumns = [
        "Notation 1",
        "Notation 2",
        "QR Code",
        "Holder Image",
        "Holder signature",
        "GUID",
      ];

      for (let column in resp.data.columns) {
        let name = resp.data.columns[column]['name'];
        if (largerColumns.includes(name)) {
          columnNames.push({ name: name, selector: column, sortable: true, wrap: true, minWidth: "200px" });
        } else {
          columnNames.push({ name: name, selector: column, sortable: true, wrap: true, minWidth: "100px" });
        }
      }
      this.allColumns = columnNames;

      let list = this.formatCertificateDates(resp.data.list)

      this.setState({
        stateData: list,
        columnData: selectedColumns,
        selected: selectedColumns,
      });
    }).catch((err) => {
      if (err.code === '0001') {
        useAppStore.getState().logOut();
      } else {
        console.log(err.message);
      }
    });
  }

  formatCertificateDates = (list) => {
    for (let i in list) {
      let row = list[i];
      for (let column of Object.keys(row)) {
        let value = row[column];
        switch (column) {
          case "holderdob":
          case "issueddate":
          case "validfrom":
          case "validto":
          case "issuedate":
            value = moment(value).format("YYYY-MM-DD");
            break;
        }
        list[i][column] = value;
      }
    }
    return list;
  }

  handleChange = ({ selectedRows }) => {
    console.log("Selected Rows: ", selectedRows);
  };

  submitColumns = () => {
    cookies.set('selectedColumns', this.state.selected, { path: '/pages' })
    this.setState({
      columnData: this.state.selected,
      showSelectColumns: false,
    });
  };

  selectCheckbox = (e) => {
    let v = e.target.value;
    let selected = this.state.selected;
    let i = selected.findIndex((item) => item === v);
    if (i === -1) {
      selected.push(v);
    } else {
      selected.splice(i, 1);
    }
    this.setState({ selected: selected });
  };

  openChangeValidTo = (certificate) => {
    this.setState({
      showChangeValidToModal: true,
      editCertificate: certificate,
      reason_for_loss: "",
    });
    useCertificateModalStore.setState({
      updateItem: certificate,
    })
  }

  changeValidTo = () => {
    let cert = useCertificateModalStore.getState().updateItem
    if (!cert.id) {
      alert("Certificate not found")
      this.loadData()
      return;
    }

    this.setState({ activateLoading: true })
    const body = {
      // reason_for_loss: "",
      validTo: cert.validto,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
    };

    FetchWithAuth("/api/certificate/changestatus/" + cert.id + "/1", requestOptions).then((data) => {
      if (data.error === true) {
        alert(data.data.message);
      }
      this.loadData()
      this.setState({ activateLoading: false, showChangeValidToModal: false })
    });
  }

  activate = (id) => {
    this.setState({ activateLoading: true })
    const body = {
      reason_for_loss: "",
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
    };

    FetchWithAuth("/api/certificate/changestatus/" + id + "/1", requestOptions).then((data) => {
      if (data.error === true) {
        alert(data.data.message);
      }
      this.loadData()
      this.setState({ activateLoading: false })
    });
  }

  testPDFGeneration = (id) => {
    this.setState({ activateLoading: true })
    const body = {
      reason_for_loss: "",
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
    };

    FetchWithAuth("/api/certificate/changestatus/" + id + "/3", requestOptions).then((data) => {
      if (data.error === true) {
        alert(data.data.message);
      }
      this.loadData()
      this.setState({ activateLoading: false })
    });
  }

  openDeactivateModal = (certificate) => {
    this.setState({
      showDeactivateModal: true,
      editCertificate: certificate,
      reason_for_loss: "",
    });
  }

  deactivate = () => {
    this.setState({ showDeactivateModal: false, activateLoading: true })

    const body = {
      reason_for_loss: this.state.reason_for_loss,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
    };

    if (this.state.editCertificate?.id > 0) {
      FetchWithAuth("/api/certificate/changestatus/" + this.state.editCertificate?.id + "/2", requestOptions).then((data) => {
        if (data.data === true) {
          this.loadData();
        }
        this.setState({ activateLoading: false })
      });
    } else {
      window.alert("No certificate selected");
      this.setState({ activateLoading: false })
    }
  }

  togleConfirmDelete = (id, number) => {
    if (!number) {
      number = ' id ' + id
    } else {
      number = ' number ' + parseInt(number, 10)
    }
    this.setState({
      deleteId: id,
      confirmDeleteMessage: 'Delete certificate ' + number + '?',
      showConfirmDeleteModal: !this.state.showConfirmDeleteModal,
    })
  }

  deleteRow = () => {
    this.setState({ deleteLoading: true })
    if (this.state.deleteId <= 0) {
      return;
    }

    FetchWithAuth(
      '/api/certificate/DeleteCertificateDraft/' + this.state.deleteId, GetAccessToken()
    ).then(data => {
      if (data.error === true) {
        window.alert(data.data.message)
      } else {
        this.loadData()
        this.setState({ showConfirmDeleteModal: false })
      }
      this.setState({ deleteLoading: false })
    })
  }

  editButton = (row) => {
    return (
      <Button className="tableButton" variant="link"
        title="Edit" onClick={() =>
          useCertificateModalStore.setState({
            show: true,
            templateData: {},
            updateItem: row,
            tabValue: 0,
          })}
      >
        <img className="tableIcon" src="/icons/edit.png" alt="edit" />
      </Button>
    )
  }

  editNotSavedButton = (row) => {
    return (
      <Button className="tableButton" variant="link"
        title="Edit" onClick={() =>
          useCertificateModalStore.setState({
            show: true,
            templateData: {},
            updateItem: row,
            tabValue: 0,
          })}
      >
        <img className="tableIcon notSavedIcon" src="/icons/edit.png" alt="edit" />
      </Button>
    )
  }

  activateButton = (row) => {
    return (
      <Button className="tableButton" variant="link"
        title="Activate" onClick={() =>
          this.activate(row.id)
        }
      >
        <img className="tableIcon" src="/icons/activate.png" alt="activate" />
      </Button>
    )
  }

  openDNVButton = (row) => {
    return (
      <>
        <Button className="tableButton" variant="link"
          title="See certificate"
          href={process.env.REACT_APP_DNVGL + row.prefix + parseInt(row.number, 10)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="tableIcon" src="/icons/dnv.png" alt="see certificate" />
        </Button>
      </>
    )
  }

  randNum = () => {
    this.setState({ random_id: Math.floor(Math.random() * 99999) })
  }

  activatedButtons = (row) => {
    return (
      <>
        {/* {process.env.REACT_APP_ENV === "development" && (
          <Button className="tableButton" variant="link"
            title="Regenerate PDFs"
            onClick={() =>
              this.testPDFGeneration(row.id)
            }
          >
            <ReplayIcon />
          </Button>
        )} */}
        <Button className="tableButton" variant="link"
          title="See card"
          onClick={() => this.randNum()}
          href={process.env.REACT_APP_PREVIEW_BASE + "/var/html/card-pdf/" + row.id + ".pdf?rid=" + this.state.random_id}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="tableIcon" src="/icons/card.png" alt="see card" />
        </Button>
        <Button className="tableButton" variant="link"
          title="See letter"
          onClick={() => this.randNum()}
          href={process.env.REACT_APP_PREVIEW_BASE + "/var/html/letter-pdf/" + row.id + ".pdf?rid=" + this.state.random_id}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="tableIcon" src="/icons/letter.png" alt="see letter" />
        </Button>
        {this.openDNVButton(row)}
        <Button className="tableButton" variant="link"
          title="Deactivate" onClick={() =>
            this.openDeactivateModal(row)
          }
        >
          <img className="tableIcon" src="/icons/deactivate.png" alt="deactivate" />
        </Button>
      </>
    )
  }

  changeValidToButton = (row) => {
    return (
      <Button className="tableButton" variant="link"
        title="Change valid to"
        onClick={() =>
          this.openChangeValidTo(row)
        }
      >
        <EditCalendarIcon className="calendar" />
      </Button>
    )
  }

  deleteButton = (row) => {
    return (
      <Button className="tableButton" variant="link"
        title="delete" onClick={() => this.togleConfirmDelete(row.id, row.number)}
      >
        <DeleteIcon className="delete" />
      </Button>
    )
  }

  render() {
    let visibleColumns: Array<any> = [];
    let selectedColumns: Array<any> = [];
    this.state.columnData.forEach(column => {
      // creates a ordered list of selected columns with the selected first.
      let i = this.allColumns.length
      while (i--) {
        if (column === this.allColumns[i].selector) {


          // moved to actions menu
          // if (column === "validto" && useAppStore.getState().userRoles.includes("admin")) {
          //   // if admin, can chenge valid_to date
          //   let validToColumn = { ...this.allColumns[i] };
          //   validToColumn.selector = (row) => {
          //     return (
          //       <>
          //         {row.validto}
          //         {(row.status === enumStatus.activated || row.status === enumStatus.deactivated) && (
          //           this.changeValidToButton(row)
          //         )}
          //       </>
          //     )
          //   }
          //   visibleColumns.push(validToColumn);
          // }


          visibleColumns.push(this.allColumns[i]);
          selectedColumns.push(this.allColumns[i]);
          this.allColumns.splice(i, 1);
        }
      }
    })

    this.allColumns = selectedColumns.concat(this.allColumns)

    visibleColumns.push({
      name: "status",
      selector: (row) => row.status,
      format: (row) =>
      ({
        '-1': "not saved",
        0: "draft",
        1: "activated",
        2: "deactivated",
      }[row?.status]),
      sortable: true,
    });

    visibleColumns.push({
      name: "Actions",
      minWidth: useAppStore.getState().userRoles.includes("admin") ? "270px" : "235px",
      selector: (row, index) => {
        return (
          <>
            {row?.status === enumStatus.temporary && (
              <>
                {this.editNotSavedButton(row)}
                {this.deleteButton(row)}
              </>
            )}
            {
              row?.status === enumStatus.draft && (
                <>
                  {this.editButton(row)}
                  {this.activateButton(row)}
                  {this.deleteButton(row)}
                </>
              )
            }
            {
              row?.status === enumStatus.activated && (
                <>
                  {useAppStore.getState().userRoles.includes("admin") && (
                    <>
                      {this.changeValidToButton(row)}
                    </>
                  )}
                  {this.activatedButtons(row)}
                </>
              )
            }
            {
              row?.status === enumStatus.deactivated && (
                <>
                  {useAppStore.getState().userRoles.includes("admin") && (
                    <>
                      {this.changeValidToButton(row)}
                    </>
                  )}
                  {this.openDNVButton(row)}
                </>
              )
            }
          </>
        );
      },
    });

    return (
      <>
        {(this.state.activateLoading) &&
          <div className="activateLoading">
            <img src="//s.svgbox.net/loaders.svg?fill=ffffff#spinning-circles"
              alt="spinner"
              height="40"
              width="40" />
          </div>
        }
        <Button
          variant="secondary"
          onClick={() => this.setState({ showSelectColumns: true })}
        >
          Select columns
        </Button>
        <Modal
          show={this.state.showSelectColumns}
          keyboard={true}
          onHide={this.submitColumns}
        >
          <Modal.Header> Select columns </Modal.Header>
          <Modal.Body>
            <div className="container">
              <Row>
                <Col>
                  {this.allColumns.map((column, index) => (
                    <div key={index}>
                      <label>
                        <input
                          type="checkbox"
                          onChange={this.selectCheckbox}
                          checked={this.state.selected.includes(
                            column.selector
                          )}
                          value={column.selector}
                        />{" "}
                        &nbsp;
                        {column.name}
                      </label>
                    </div>
                  ))}
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.submitColumns}>Close</Button>
          </Modal.Footer>
        </Modal>

        <DataTable
          columns={visibleColumns}
          data={this.state.stateData}
          pagination
          highlightOnHover
          noHeader
          onRowClicked={(row, event) => {
            this.showModal(row);
          }}
        />

        {/* Reason for loss */}
        <Modal show={this.state.showDeactivateModal} backdrop="static" keyboard={false}>
          <Modal.Header>
            <Modal.Title>Deactivate certificate</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert alert-danger">
              Deactivate certificate No. {this.state.editCertificate?.number}{this.state.editCertificate?.lastname && ", belonging to " + this.state.editCertificate.lastname}
            </div>
            <div className="form-group">
              <label htmlFor="reason_for_loss">
                Reason for loss:
              </label>
              <textarea
                value={this.state.reason_for_loss}
                onChange={(e) => this.setState({ reason_for_loss: e.target.value })}
                className="form-control"></textarea>
            </div>
          </Modal.Body>
          <CloseSubmitFooter
            onClose={() => this.setState({ showDeactivateModal: false })}
            onSubmit={this.deactivate}
          />
        </Modal>

        {/* Change valid to */}
        <Modal show={this.state.showChangeValidToModal} backdrop="static" keyboard={false}>
          <Modal.Header>
            <Modal.Title>Change valid to</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert alert-danger">
              Changing certificate No. {this.state.editCertificate?.number}{this.state.editCertificate?.lastname && ", belonging to " + this.state.editCertificate.lastname}
            </div>
            <div className="form-group">
              <label htmlFor="reason_for_loss">
                Changing valid to will{this.state.editCertificate?.status === enumStatus.activated ? " deactivate and reactivate " : " activate "}the certificate
              </label>
              <input
                type="date"
                required
                value={useCertificateModalStore.getState().updateItem.validto}
                onChange={(e) =>
                  useCertificateModalStore.getState().editForm(["validto", e.target.value])
                }
                placeholder="Valid to"
                className="form-control"
                id="validto"
              />
            </div>
          </Modal.Body>
          <CloseSubmitFooter
            onClose={() => this.setState({ showChangeValidToModal: false })}
            onSubmit={this.changeValidTo}
            loading={this.state.activateLoading}
          />
        </Modal>

        <ConfirmDelete
          show={this.state.showConfirmDeleteModal}
          onHide={this.togleConfirmDelete}
          confirmDelete={this.deleteRow}
          message={this.state.confirmDeleteMessage}
          loading={this.state.deleteLoading}
        />
      </>
    );
  }
}
