import React, { useState } from "react";
// import { styled } from "@mui/material/styles";
// import Paper from "@mui/material/Paper";
import Button from "react-bootstrap/Button";
// import MyModal from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { create } from "zustand";
import moment from "moment";
import { FetchWithAuth } from "../../services/AuthService";
// import { useAppStore } from "../../App";
import IssuedCertificateInput from "./EditCertificate";
import ListCertificates from "./ListCertificates";

interface IProps {
  props?: string;
}

const _item = {
  dismissed: false,
  externalid: "",
  guid: "2f981d3d-2174-4fa4-8f74-08a910ce7b4f",
  holderdob: "1000-01-01T00:00:00Z",
  holdername: "",
  firstname: "",
  lastname: "",
  holdersignature: "",
  holder_image: "",
  id: 149,
  issueddate: "1000-01-01T00:00:00Z",
  issuerlogo: "",
  logo: "",
  managedby: 0,
  metadata: {
    author: 0,
    author_name: "",
    contenttype: "certificate",
    cuid: "",
    modified: "0001-01-01T00:00:00Z",
    name: "",
    published: "0001-01-01T00:00:00Z",
    version: 0,
  },
  notations1: "",
  notations2: "",
  notationsid: 0,
  number: "sstest2",
  prefix: "",
  privatereasondismissed: "",
  publicreasondismissed: "",
  reason_for_loss: "",
  qrcode: "",
  status: 0,
  templateid: 52,
  validfrom: "1000-01-01T00:00:00Z",
  validto: "1000-01-01T00:00:00Z",
};

export type FormItem = typeof _item;

export const enumStatus = {
  temporary: '-1',
  draft: 0,
  activated: 1,
  deactivated: 2,
};

export const useCertificateModalStore = create<{
  show: boolean;
  updateItem: Partial<FormItem>;
  editForm: ([key, value]: [keyof FormItem, any]) => any;
  changeStatus(item: FormItem, status: number): any;
  templateData: any;
  createByTemplate(): any;
  tabValue: number;
}>((setState, getstate) => ({
  tabValue: 0,
  show: false,
  updateItem: {} as FormItem,
  editForm([key, value]) {
    setState((state) => ({
      ...state,
      updateItem: {
        ...state.updateItem,
        [key]: value,
      },
    }));
  },
  async changeStatus(item, status) {
    // if (status === enumStatus.activated) {
    //   await FetchWithAuth(`/api/certificate/activate?id=${item.id}`);
    // }
  },
  templateData: {},
  async createByTemplate() {
    const templateId = getstate().updateItem.templateid;
    const res = await FetchWithAuth(
      `/api/certificate/create?templateid=${templateId}`,
      {
        method: "POST",
      }
    ).then((res) => res.json());
    setState({ templateData: res?.data });
  },
}));

export default function CertificateController() {
  const { show, updateItem } = useCertificateModalStore();
  const [updateCount, setUpdateCount] = useState(0);

  const closeModal = () => {
    setUpdateCount(updateCount => updateCount + 1)
    
    if (updateItem.number === enumStatus.temporary) {
      console.log("Close, delete");
    } else {
      console.log("Close, do nothing");
    }
    useCertificateModalStore.setState({ show: false })
  }

  const saveModal = () => {
    setUpdateCount(updateCount => updateCount + 1)

    console.log("Update, ", updateItem?.id)
    
    useCertificateModalStore.setState({ show: false })
  }


  return (
    //html kode som viser layout for kategorien "Issued Certificaes" og kaller på
    //listcertificates og registercertificates

    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <br />
              <h2>Issued Certificates</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card-box">
                <div className="create-new-button">
                  <Button
                    onClick={() =>
                      useCertificateModalStore.setState({
                        show: true,
                        templateData: {},
                        tabValue: 0,
                        updateItem: {
                          issueddate: moment().format("YYYY-MM-DD"),
                          validfrom: moment().format("YYYY-MM-DD"),
                        },
                      })
                    }
                    type="button"
                    className="btn btn-info"
                    data-toggle="modal"
                    data-target="#myModal"
                    color="primary"
                  >
                    Create a certificate
                  </Button>
                </div>
                {/* <div className="table-responsive">
                  <table className="table table-hover m-0 table table-actions-bar table-centered">
                    <div className="IssuedCertificatesTable">
                      <h5 className="mt-0 font-14 mb-3"> </h5> */}
                <Modal
                  size="xl"
                  show={show}
                  onHide={closeModal}
                  keyboard={true}
                  backdrop="static"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {updateItem?.id
                        ? "Update certificate"
                        : "Create a certificate"}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <IssuedCertificateInput
                      onCloseModal={closeModal}
                      onSaveModal={saveModal}
                    />
                  </Modal.Body>
                </Modal>

                <ListCertificates updateCount={updateCount} />
                {/* <ListCertificates /> */}
                {/* </div>
                  </table>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
