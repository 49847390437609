import React from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButton } from "@mui/material";
import Button from "react-bootstrap/Button";
import { useAppStore } from "../App";


const Topbar = ({ toggles }) => {
  const { isLoggedin, logOut, userName } = useAppStore();

  return (
    <>
      {isLoggedin && (
        <AppBar style={{ background: "#0D173A" }}>
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" edge="start"
              onClick={toggles.toggleMobileOpen}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            <Typography variant="h5" noWrap>
              <img src="/images/logo.png" style={{ maxHeight: "50px" }} alt="logo" />
              &nbsp;&nbsp;Digimaker Certficate Management
            </Typography>


            <div className="topbarRightSide">

              <div className="nameLogout">
                <div className="displayName">{userName}</div>
                <Button
                  className="logoutButton bi bi-box-arrow-right"
                  color="#fff"
                  onClick={logOut}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-box-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      )}
    </>
  )
}

export default Topbar;