import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { FetchWithAuth } from '../../services/AuthService'
import { create } from 'zustand'
import CloseSubmitFooter from '../../modal/closeSubmitFooter'

const _formItem = {
  'authenticationpassword': 'sss',
  'authenticationusername': 's',
  'id': '22323',
  'name': 'ssteset2',
  'urlcreate': '11',
  'urldismiss': '22',
}

type FormItem = typeof _formItem
export const useSettingsManagerInput = create<{
  showModal: boolean
  form: Partial<FormItem>
  editForm: ([key, value]: [keyof FormItem, any]) => any
  open({ item }: { item?: Partial<FormItem> }): void
  reloadList: any
}>((setState, getState) => ({
  showModal: false,
  form: {},
  editForm([key, value]) {
    setState(state => ({
      ...state,
      form: {
        ...state.form,
        [key]: value,
      },
    }))
  },
  open({ item }: { item?: Partial<FormItem> }) {
    setState({
      showModal: true,
      form: {
        ...(item ?? {}),
      },
    })
  },
  reloadList() {
  },
}))

export default function SettingsManagerInput() {
  const { form, editForm, showModal } = useSettingsManagerInput()
  const { name, urlcreate, authenticationusername, urldismiss, authenticationpassword } = form
  const [isLoading, setIsLoading] = useState(false)

  function handleSubmit() {
    setIsLoading(true)

    const body = {
      authenticationpassword,
      authenticationusername,
      name,
      urlcreate,
      urldismiss,
    }

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body),
    }

    FetchWithAuth(
      form?.id ?
        `/api/content/update/certificate_manager/${form.id}` :
        '/api/content/create/certificate_manager',
      requestOptions
    ).then((res) => {
      console.log(res)
      if (res.status === 200) {
        setIsLoading(false)
        useSettingsManagerInput.setState({ showModal: false })
        useSettingsManagerInput.getState()?.reloadList?.()
      }
    }).catch((err) => {
      console.log(err.message)
      setIsLoading(false)
    })
  }


  return (
    <Modal
      size="xl"
      show={showModal}
      onHide={() => useSettingsManagerInput.setState({ showModal: false })}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{form?.id ? 'Update Manager' : 'Create Manager'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container" id="myModal">
          <form action="#" className="input-group">

            <div className="form-group">
              <label htmlFor="name">Name <span className="text-danger">*</span></label>
              <input type="text"
                value={name}
                onChange={(e) => editForm(['name', e.target.value])}
                placeholder="Name"
                className="form-control"
                id="name" />
            </div>

            <div className="form-group">
              <label htmlFor="urlCreate">url Create <span className="text-danger">*</span></label>
              <input type="text"
                value={urlcreate}
                onChange={(e) => editForm(['urlcreate', e.target.value])}
                placeholder="url Create"
                className="form-control"
                id="urlCreate" />
            </div>


            <div className="form-group">
              <label htmlFor="urlDismiss">urlDismiss <span className="text-danger">*</span></label>
              <input type="text"
                value={urldismiss}
                onChange={(e) => editForm(['urldismiss', e.target.value])}
                placeholder="url Dismiss"
                className="form-control"
                id="urlDismiss" />
            </div>


            <div className="form-group">
              <label htmlFor="authenticationUsername">Auth
                Username <span className="text-danger">*</span></label>
              <input type="text"
                value={authenticationusername}
                onChange={(e) => editForm(['authenticationusername', e.target.value])}
                placeholder="authentication Username"
                className="form-control"
                id="authenticationUsername" />
            </div>

            <div className="form-group">
              <label htmlFor="authenticationPassword">Auth
                Password <span className="text-danger">*</span></label>
              <input type="text"
                value={authenticationpassword}
                onChange={(e) => editForm(['authenticationpassword', e.target.value])}
                placeholder="authentication Password"
                className="form-control"
                id="authenticationPassword" />
            </div>
          </form>
        </div>
      </Modal.Body>
      <CloseSubmitFooter
        onClose={() => useSettingsManagerInput.setState({ showModal: false })}
        onSubmit={handleSubmit}
        submitText={form?.id ? 'Save' : 'Create'}
        loadingText={form?.id ? 'Saving...' : 'Creating...'}
        loading={isLoading}
        showLoadingSpinner={true}
      />
    </Modal >
  )

}
