import { Tab, Tabs } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import { FetchWithAuth } from "../../services/AuthService";
import { useCertificateModalStore } from "./CertificateController";
import { Util } from "../../components/util";
import FileUpload from 'digimaker-ui/FileUpload';
import TextField from '@material-ui/core/TextField';
import CloseSubmitFooter from "../../modal/closeSubmitFooter";

// Denne komponenten viser inputfelt som må fylles ut for at sertifikatet skal utstedes
// Det kalles også på et eksternt API for at informasjonen som fylles ut skal bli lagret og lastet opp i tabellen
export default function IssuedCertificateInput(props: { onCloseModal(): void, onSaveModal(): void, }) {

  const { updateItem, editForm, templateData, createByTemplate, tabValue } = useCertificateModalStore();
  const [isLoading, setIsLoading] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [printTemplate, setPrintTemplate] = useState({} as any);
  const [notations1_list, setNotations1_list] = useState([] as any);
  const [notations2_list, setNotations2_list] = useState({} as any);
  const [notations2_options, setNotations2_options] = useState([] as any);

  useEffect(() => {
    FetchWithAuth("/api/content/list/certificate_template")
      .then((res) => res.json())
      .then((data) => {
        setTemplateList(data.data.list);

        // if edit set notations from templateid
        if (updateItem?.templateid) {
          const actTemplate: any = data.data.list.find((v: any) => v.id === updateItem.templateid);
          setNotations(actTemplate)
        }
      });

    FetchWithAuth("/api/certificate/print_template/" + updateItem.id)
      .then((res) => res.json())
      .then((data) => {
        setPrintTemplate(data.data);
      });
  }, []);

  // Når handleSubmit trigges så vil feltene som har blitt fylt sendt til APIet
  async function handleSubmit(e, changeTabValue: number = 0) {
    e.preventDefault();
    setIsLoading(true);

    const body = {
      ...updateItem,
      // Determine if it is a new addition. If it is new, you need to create it based on a template and then save it
      ...(templateData?.id
        ? {
          id: templateData?.id,
        }
        : {}),
    };

    //Gjør om verdiene til feltene tiL JSON stringer slik at de kommer overens med det i APIet
    const requestOptions = {
      method: "POST",
      // headers: {'Content-Type': 'application/json'}, should not have a header
      body: JSON.stringify(body),
    };

    //Kaller på  API for oppretting av sertifikat, og legger til de utfylte feltene
    FetchWithAuth(`/api/certificate/save?id=${body?.id}`, requestOptions)
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          if (changeTabValue === 0) {
            props.onSaveModal()
          } else {
            useCertificateModalStore.setState({ tabValue: changeTabValue })
          }
        } else {
          res.json().then((data) => {
            if (data.error === true) {
              alert(data.data.message);
            }
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  const changeTemplate = (e: any) => {
    const templateid = parseInt(e.target.value);
    const actTemplate: any = templateList.find((v: any) => v.id === templateid);
    editForm(["templateid", templateid]);
    editForm([
      "validto",
      moment(updateItem.validfrom)
        .add(actTemplate?.validfor, "months")
        .format("YYYY-MM-DD"),
    ]);

    setNotations(actTemplate)
  };

  function setNotations(actTemplate) {
    // set options for notation 1 and 2
    // handels if notations values are already set
    var notations1 = [];
    if (actTemplate?.notations1) {
      notations1 = actTemplate.notations1.split(";");
      // notations1 = actTemplate.notations1.split(",").map(function (item) {
      //   return item.trim();
      // });

      // remove undefined and duplicates
      notations1 = notations1.filter(String)
      notations1 = notations1.filter((element, index) => {
        return notations1.indexOf(element) === index;
      });

      setNotations1_list([...notations1]);
    }

    if (actTemplate?.notations2) {
      const notation1_groups = actTemplate.notations2.split("\n");
      // const notation1_groups = actTemplate.notations2.split("\n").map(function (item) {
      //   return item.trim();
      // });

      var idx = 0;
      var name = '';
      var notations2 = {};
      if (notation1_groups) {
        notation1_groups.forEach(element => {
          var groups = element.split(":")
          groups = groups.length > 1 ? groups[1] : groups[0]

          if (idx < notations1.length) {
            name = notations1[idx];
            notations2[name] = groups.split(";");
            // notations2[name] = groups.split(",").map(function (item) {
            //   return item.trim();
            // });

            // remove undefined and duplicates
            notations2[name] = notations2[name].filter(String)
            notations2[name] = notations2[name].filter((element, index) => {
              return notations2[name].indexOf(element) === index;
            });
          }
          idx += 1;
        });
      }

      setNotations2_list(notations2);

      // if edit, set selected checkboxes for notations2 to checked
      if (updateItem?.notations2) {
        const selected = updateItem?.notations2?.split(";");
        // const selected = updateItem?.notations2?.split(",").map(function (item) {
        //   return item.trim();
        // });

        if (updateItem.notations1 && updateItem.notations1 in notations2) {
          // console.log("selected", selected)
          var options = notations2[updateItem.notations1].map(option => {
            var checked = selected.includes(option)
            return ({ 'name': option, 'checked': checked })
          })
          setNotations2_options(options)
        }
      }
    }
  }

  const changeNotation1 = (e: any) => {
    editForm(["notations1", e.target.value])
    editForm(["notations2", ""])

    var options = notations2_list[e.target.value].map(option => {
      return ({ 'name': option, 'checked': false })
    })

    setNotations2_options(options)
  }

  const changeNotation2 = (e: any) => {
    const curr = notations2_options;
    var index = curr.findIndex((obj => obj.name === e.target.value))
    curr[index].checked = !(curr[index].checked);
    setNotations2_options([...curr]);

    var notation = '';
    if (curr) {
      curr.forEach(option => {
        if (option.checked) {
          notation += option.name
        }
      });
    }

    editForm(["notations2", notation])
  }

  const validFromChange = (e: any) => {
    // setValidfrom(e.target.value);
    editForm(["validfrom", e.target.value]);
    const actTemplate: any = templateList.find(
      (v: any) => v.id === updateItem?.templateid
    );
    if (!updateItem?.templateid || !actTemplate) {
      return;
    }
    editForm([
      "validto",
      moment(e.target.value)
        .add(actTemplate?.validfor, "months")
        .format("YYYY-MM-DD"),
    ]);
  };

  const setTabValue = (e: any, value: number) => {
    if (value === 0) {
      useCertificateModalStore.setState({ tabValue: value })
    } else {
      handleSubmit(e, value);
    }
  }

  return (
    // HTML kode for inputfeltene, hvert felt har en verdi, og når feltene fylles ut så blir de verdiene lagret
    //i en funksjon og sendt til APIet når man trykker på submit
    <div className="container" id="myModal">
      <div className="modal-body">
        <div>
          <form onSubmit={handleSubmit}>
            {!(updateItem?.id || templateData?.id) && (
              <>
                <p className="sub-header"></p>
                <div className="form-group">
                  <label htmlFor="template">
                    Template <span className="text-danger">*</span>
                  </label>
                  <select
                    id="template"
                    className="form-control"
                    onChange={changeTemplate}
                    value={updateItem?.templateid}
                  >
                    <option value={0}>Select template</option>
                    {templateList.map((item: any) => (
                      <option key={item.id} value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <Button
                    style={{ float: "none" }}
                    variant="primary"
                    onClick={createByTemplate}
                  >
                    Get certificate No.
                  </Button>
                </div>
              </>
            )}
            {(updateItem?.id || templateData?.id) && (
              <>
                <Tabs
                  value={tabValue}
                  onChange={(e, v) =>
                    setTabValue(e, v)
                  }
                >
                  <Tab label="Form" />
                  <Tab label="Card preview" />
                  <Tab label="Letter preview" />
                </Tabs>
                {tabValue === 0 && (
                  <div>
                    <div className="form-group">
                      <label htmlFor="holderdob">Certificate No. : </label>
                      <span style={{ margin: "0 6px" }}>
                        {updateItem?.number || templateData?.number}
                      </span>
                    </div>
                    <Row>
                      <Col xs={4}>
                        <div className="form-group">
                          <label htmlFor="holderdob">
                            Holder D.O.B <span className="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            required
                            value={updateItem.holderdob}
                            onChange={(e) =>
                              editForm(["holderdob", e.target.value])
                            }
                            placeholder="Holder D.O.B"
                            className="form-control"
                            id="holderdob"
                          />
                        </div>
                      </Col>
                    </Row>

                    <div className="form-group">
                      <label htmlFor="firstname">
                        First name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        value={updateItem.firstname}
                        onChange={(e) =>
                          editForm(["firstname", e.target.value])
                        }
                        placeholder="First Name"
                        className="form-control"
                        id="firstname"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="lastname">
                        Last name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        value={updateItem.lastname}
                        onChange={(e) =>
                          editForm(["lastname", e.target.value])
                        }
                        placeholder="Last Name"
                        className="form-control"
                        id="lastname"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="holder_image" style={{ width: '120px' }}>
                        Holder image <span className="text-danger">*&nbsp;</span>
                      </label>

                      <FileUpload name="holder_image"
                        service="content"
                        format="image/*"
                        value={updateItem.holder_image}
                        onSuccess={(data) => {
                          {
                            process.env.REACT_APP_OS === "windows" ?
                              editForm(["holder_image", "var/upload_temp/" + data.nameUploaded.split("\\").pop()]) : // hacky fix for windows dev
                              editForm(["holder_image", "var/" + data.nameUploaded])
                          }
                        }} />
                      {updateItem.holder_image && <img style={{ height: '50px' }} src={'/' + updateItem.holder_image} alt="holder profile" />}
                    </div>

                    <div className="form-group">
                      <label htmlFor="holdersignature" style={{ width: '120px' }}>
                        Holder signature <span className="text-danger">*&nbsp;</span>
                      </label>

                      <FileUpload name="holdersignature"
                        service="content"
                        format="image/*"
                        value={updateItem.holdersignature}
                        onSuccess={(data) => {
                          {
                            process.env.REACT_APP_OS === "windows" ?
                              editForm(["holdersignature", "var/upload_temp/" + data.nameUploaded.split("\\").pop()]) : // hacky fix for windows dev
                              editForm(["holdersignature", "var/" + data.nameUploaded])
                          }
                        }} />
                      {updateItem.holdersignature && <img style={{ height: '50px' }} src={'/' + updateItem.holdersignature} alt="holder signature" />}
                    </div>

                    <div className="form-group">
                      <label htmlFor="validityClass">
                        Validity class:
                        <span style={{ fontWeight: 'bold', fontSize: '1.25em' }}>
                          &nbsp;{updateItem.notations1}{updateItem.notations2 && (', ')}{updateItem.notations2}
                        </span>
                      </label>

                      <Row>
                        <Col xs={4}>
                          {notations1_list.map((name) => (
                            <div key={name}>
                              <Form.Check
                                type="radio"
                                value={name}
                                label={name}
                                checked={updateItem.notations1 === name}
                                name="notation1"
                                id={name}
                                onChange={changeNotation1}
                              />
                            </div>
                          ))}
                        </Col>
                        <Col xs={4}>
                          {notations2_options.map((option) => (
                            <div key={option?.name}>
                              <Form.Check
                                type="checkbox"
                                value={option?.name}
                                label={option?.name}
                                checked={option.checked}
                                name="notation2"
                                onChange={changeNotation2}
                              />
                            </div>
                          ))}
                        </Col>
                      </Row>
                    </div>

                    <Row>
                      <Col xs={4}>
                        <div className="form-group">
                          <label htmlFor="issueddate">
                            Issued date <span className="text-danger">*</span>
                          </label>
                          <input
                            required
                            type="date"
                            value={updateItem.issueddate}
                            onChange={(e) =>
                              editForm(["issueddate", e.target.value])
                            }
                            placeholder="Issued date"
                            className="form-control"
                            id="issueddate"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={4}>
                        <div className="form-group">
                          <label htmlFor="validfrom">
                            Valid from <span className="text-danger">*</span>
                          </label>
                          <input
                            required
                            type="date"
                            value={updateItem.validfrom}
                            onChange={validFromChange}
                            placeholder="Valid from"
                            className="form-control"
                            id="validfrom"
                          />
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className="form-group">
                          <label htmlFor="validto">
                            Valid to <span className="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            required
                            value={updateItem.validto}
                            onChange={(e) =>
                              editForm(["validto", e.target.value])
                            }
                            placeholder="Valid to"
                            className="form-control"
                            id="validto"
                          />
                        </div>
                      </Col>
                    </Row>

                    <CloseSubmitFooter
                      onClose={() => props.onCloseModal()}
                      onSubmit={handleSubmit}
                      submitText={'Save'}
                      loadingText={'Saving...'}
                      loading={isLoading}
                      showLoadingSpinner={true}
                    />
                  </div>
                )}
                {tabValue === 1 && <div>
                  {/* <div style={{ position: 'relative', height: '300px' }} dangerouslySetInnerHTML={{ __html: Util.washVariables(updateItem, printTemplate?.card) }}></div> */}
                  <div className="previewCard">
                    <iframe
                      title="HTML Content"
                      src={process.env.REACT_APP_PREVIEW_BASE + '/api/certificate/serve_card/' + (updateItem?.id ? updateItem?.id : templateData.id)}
                      width="1300"
                      height="828"
                      frameBorder="0"
                      scrolling="no"
                    ></iframe>
                  </div>
                </div>}
                {tabValue === 2 && <div>
                  {/* <div style={{ position: 'relative' }} dangerouslySetInnerHTML={{ __html: Util.washVariables(updateItem, printTemplate?.letter) }}></div> */}
                  <div className="previewLetter">
                    <iframe
                      title="HTML Content"
                      src={process.env.REACT_APP_PREVIEW_BASE + '/api/certificate/serve_letter/' + (updateItem?.id ? updateItem?.id : templateData.id)}
                      width="1044"
                      height="720"
                      frameBorder="0"
                    ></iframe>
                  </div>
                </div>}
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
