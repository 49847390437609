import React, { useEffect, useState } from "react"
import { FetchWithAuth } from "../../services/AuthService";
import { Button, Form } from "react-bootstrap";

export const AssignRole = (props: { id: number, onSuccess: () => void, onCancel: () => void }) => {
  const [roles, setRoles] = useState([] as any);
  const [assigned, setAssigned] = useState([] as any);
  const [newRole, setNewRole] = useState(0);

  useEffect(() => {
    FetchWithAuth('/api/access/roles/' + props.id).then(res => res.json()).then(data => {
      if (data.data) {
        setAssigned(data.data);
      }
    })

    FetchWithAuth('/api/content/list/role').then(res => res.json()).then(data => {
      setRoles(data.data.list);
    })
  }, []);

  const submit = () => {
    if (newRole) {
      FetchWithAuth('/api/access/assign/' + props.id + '/' + newRole).then(res => res.json()).then(data => {
        props.onSuccess();
      })
    }
  }

  return <div>
    <Form.Group>
      <Form.Label>Roles</Form.Label>
      <div>
        {roles.map(item => <Form.Check id={'role' + item['id']} name={"role"} onClick={() => setNewRole(item['id'])} type='radio' disabled={assigned.find(i => i['id'] === item['id'])} label={item.name} />)}
      </div>
      <div>
        <br />
        <Button onClick={() => submit()}>Submit</Button>
        <Button variant="light" onClick={() => props.onCancel()}>Cancel</Button>
      </div>
    </Form.Group>
  </div>
}