import * as React from "react";
// import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// import Toolbar from "@material-ui/core/Toolbar";
// import Typography from "@material-ui/core/Typography";
// import MenuIcon from "@material-ui/icons/Menu";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { ListItemButton } from "@mui/material";
import { useAppStore } from "../App";


const Navbar = ({ toggles }) => {
  const theme = useTheme();
  const { isLoggedin, userRoles } = useAppStore();

  const container = window !== undefined ? () => window.document.body : undefined;

  // Sjekker om man er på logg inn eller logg ut siden
  // const CheckIfLoginPage = () => {
  //   if (urlPathName === "/pages/LoginPage") {
  //     return true;
  //   } else if (urlPathName === "/") {
  //     return true;
  //   } else if (urlPathName === "localhost:3000") {
  //     return true;
  //   } else if (urlPathName === "/pages/Logoutpage") {
  //     return true;
  //   } else return false;
  // };

  // Her listes alle kategoriene i navigasjonsbaren
  const drawer = (
    <div id="sidebar-menu" className="mm-active">
      <Divider />

      <List component="nav">
        {/* <ListItem button component={Link} to="/pages/Dashboard">
          <ListItemText primary="Dashboard" />
        </ListItem> */}

        <ListItem button component={Link} to="/pages/Certificates">
          <ListItemText primary="Certificates" />
        </ListItem>

        {userRoles.includes("admin") && (
          <>
            <ListItemButton onClick={toggles.toggleMenuOpen}>
              <ListItemText primary="Settings" />
              {toggles.menuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={toggles.menuOpen} timeout="auto" unmountOnExit>
              <List>
                <ListItem button component={Link} to="/pages/CertificateTemplate">
                  <ListItemText primary="Certificate templates" />
                </ListItem>

                <ListItem button component={Link} to="/pages/Templates">
                  <ListItemText primary="Print templates" />
                </ListItem>

                <ListItem button component={Link} to="/pages/CertificateManagers">
                  <ListItemText primary="Certificate managers" />
                </ListItem>

                <ListItem button component={Link} to="/pages/Users">
                  <ListItemText primary="Users" />
                </ListItem>
              </List>
            </Collapse>
          </>
        )}

      </List>

      <Divider />
    </div>
  );

  return (
    <>
      {isLoggedin && (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />

          <nav>
            {/* For små skjermer*/}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={toggles.mobileOpen}
                onClose={toggles.toggleMobileOpen}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>

            {/* For større skjermer */}
            <Hidden xsDown implementation="css">
              <Drawer variant="permanent" open>
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        </Box>
      )}
    </>
  );
}

export default Navbar;

