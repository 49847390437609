import * as React from "react";
import { Modal, Button } from "react-bootstrap";

const CloseSubmitFooter = ({
  onClose,
  onSubmit,
  loading = false,
  showLoadingSpinner = false,
  submitText = "Submit",
  loadingText = "Loading..."
}) => {
  return (
    <Modal.Footer>
      <Button variant="secondary"
        onClick={onClose}>
        Close
      </Button>
      <Button
        variant="primary"
        onClick={onSubmit}
        disabled={loading}>
        {(loading && showLoadingSpinner) &&
          <img src="//s.svgbox.net/loaders.svg?fill=ffffff#spinning-circles"
            alt="spinner"
            height="25"
            width="25" />
        }
        {loading ? loadingText : submitText}
      </Button>
    </Modal.Footer>
  )
}

export default CloseSubmitFooter;