import React, { useEffect, useState } from "react"
import { FetchWithAuth, GetAccessToken } from "../../services/AuthService";
import { Button, Col, Form, Row } from "react-bootstrap";

export const UpdateUser = (props: { id: number, onSuccess: () => void, onCancel: () => void }) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    FetchWithAuth(
      '/api/content/get/user/' + props.id, GetAccessToken()
    ).then(res => res.json()).then(data => {
      setUser(data.data);
    });

  }, []);

  const submit = (e) => {

    e.preventDefault();
    const fData = new FormData(e.target);
    const object = {};
    const selectedRoles = [] as any;
    fData.forEach(function (value, key) {
      if (value) {
        if (key.startsWith('role_')) {
          selectedRoles.push(value);
        } else {
          object[key] = value;
        }
      }
    });

    FetchWithAuth(
      '/api/content/update/user/' + props.id,
      {
        method: 'POST',
        body: JSON.stringify(object)
      }
    ).then(res => res.json()).then(data => {
      props.onSuccess();
    });

  }

  return <div>
    <Form onSubmit={submit}>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>First name</Form.Label>
            <Form.Control name="firstname" type="text" defaultValue={user['firstname']} placeholder="First name" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Last name</Form.Label>
            <Form.Control name="lastname" type="text" defaultValue={user['lastname']} placeholder="Last name" />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control name="email" type="email" defaultValue={user['email']} placeholder="Email" />
      </Form.Group>
      <Form.Group>
        <Form.Label>Mobile</Form.Label>
        <Form.Control name="mobile" type="text" defaultValue={user['mobile']} placeholder="Mobile" />
      </Form.Group>
      <Form.Group>
        <Form.Label>Login</Form.Label>
        <Form.Control name="login" type="text" defaultValue={user['login']} placeholder="Login account" />
      </Form.Group>
      <Form.Group>
        <Form.Label>Password</Form.Label>
        <Form.Control name="password" type="password" placeholder="Empty for unchange" />
      </Form.Group>
      <div>
        <br />
        <Button type="submit">Update</Button>
        <Button variant="light" onClick={() => props.onCancel()}>Cancel</Button>
      </div>
    </Form>
  </div>
}