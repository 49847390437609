import React, { useEffect, useState } from "react"
import { Alert, Button, Form } from "react-bootstrap"
import { Link, useParams } from "react-router-dom";

export const ResetPasswordConfirm = ()=>{
    const params = useParams();

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(()=>{

    },[]);


    const reset = ()=>{
        if( !password || !password2 ){
            setError('Passwords can not be empty');
            return;
        }

        if( password !== password2 ){
            setError("Passwords don't match");
            return
        }
        fetch('/api/user/resetpassword-confirm/'+params['hash'],{method:'POST', body:JSON.stringify({password: password})}).then(res=>res.json()).then(data=>{
            if( data.error === false ){
                setSuccess(true)
            }else{
                setError(data.data.message);
            }
        });
    }
    return <div>

        <div className="login-main">
            <h2>Reset password</h2>
            {error&&<Alert variant="warning">{error}</Alert>}
            {!success&&<div>
            <Form.Group>
                <Form.Label>Please input new password:</Form.Label> 
                <Form.Control
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
            </Form.Group>

            <Form.Group>
                <Form.Label>Repeat:</Form.Label> 
                <Form.Control
                  type="password"
                  onChange={(e) => setPassword2(e.target.value)}
                />
            </Form.Group>

            <div>
                <br />
                <Button onClick={reset}>Reset</Button>
            </div>
            </div>}
            {success&&<Alert>Your password is updated! <Link to="/">Login</Link></Alert>}
        </div>
    </div>
}