import React, { useState } from "react"
import { Alert, Button, Form } from "react-bootstrap"
import { Link } from "react-router-dom";

export const ForgotPassword = ()=>{
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    

    const resetPassword = ()=>{
        fetch('/api/user/resetpassword/'+email).then(res=>res.json()).then(data=>{
            if( data.error ){
                setError(data.data.message);                
            }else{
                setError('');
                setSuccess(true);
            }
        });
    }

    return <div>
        <div className="login-main">
        <br />
        <h1>Forgot password</h1>
        <br />
            {error&&<Alert>{error}</Alert>}
            {success&&<Alert>An email is sent to your email address. Please follow instruction from there. <br />
                <Link to="/">Go back</Link>
            </Alert>}
            {!success&&<div><Form.Group>
                <Form.Label>Please input your email:</Form.Label> 
                <Form.Control
                  type="text"
                  onChange={(e) => setEmail(e.target.value)}
                />
            </Form.Group>
            <br />
            <Button onClick={resetPassword}>Reset password</Button></div>}
            
        </div>
    </div>
}