import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAppStore } from "../App";


const RoleProtectedRoute = ({ roles, redirectPath = '/', component: Component, ...restOfProps }) => {
  const { isLoggedin, initialLoad, userRoles } = useAppStore();
  let hasAccess = false;

  if (!initialLoad) {
    return null
  }

  // check role access
  if (roles.length === 0) {
    hasAccess = true
  } else {
    userRoles.forEach(userRole => {
      if (roles.includes(userRole)) {
        hasAccess = true
      }
    });
  }

  // check if logged in
  if (!isLoggedin) {
    hasAccess = false;
  }

  // console.log("roles", roles, "userRoles", userRoles, "hasAccess", hasAccess, "isLoggedIn", isLoggedin)

  return (
    <>
      {/* {console.log("RENDERING", hasAccess, isLoggedin)} */}
      <Route
        {...restOfProps}
        render={(props) =>
          hasAccess ? <Component {...props} /> : <Redirect to={redirectPath} />
        }
      />
    </>
  )
}

export default RoleProtectedRoute;