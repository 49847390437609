import React, { useEffect } from 'react';
import Topbar from '../Topbar';
import Navbar from '../Navbar';
import { useAppStore } from '../../App';

const TopNavbarLayout = () => {
  const { isLoggedin } = useAppStore();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const settingsRoutes = [
    '/pages/CertificateTemplate',
    '/pages/Templates',
    '/pages/CertificateManagers',
    '/pages/Users'
  ]

  // collaps navigations when screen is small, toggle with a hamburger menu
  const toggles = {
    toggleMobileOpen: () => {
      setMobileOpen(!mobileOpen);
    },

    // drop down settings menu
    toggleMenuOpen: () => {
      setMenuOpen(!menuOpen);
    },
    mobileOpen,
    menuOpen
  }

  useEffect(() => {
    if (settingsRoutes.includes(window.location.pathname)) {
      toggles.toggleMenuOpen();
    }
  }, [])

  return (
    <>
      {isLoggedin && (
        <>
          <Topbar toggles={toggles} />
          <Navbar toggles={toggles} />
        </>
      )}
    </>
  )
}

export default TopNavbarLayout;